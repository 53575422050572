<template>
  <div id="conversation">
   <div ref="chatview" class="conversation-container">
      <div
       v-for="message in messages" :key="message"
       class="bubble-container"
       :class="{ myMessage: (message.person=='friend')}"
      >
        <div class="bubble">
          <img src="@/assets/friend.png" v-if="message.person=='friend'" width="40" height="40">
          <img src="@/assets/miura.png" v-if="message.person=='me'" width="40" height="40">
          <!-- <div class="name" v-if="message.person=='me'">ミウラ:</div>
          <div class="name" v-if="message.person=='friend'">トモダチ:</div> -->
          <div class="message">{{ message.text }}</div>
        </div>
      </div>   
    </div>
    <div class="input-container">
      <input v-model="messageText" placeholder="Enter your message">
      <button @click="sendMessage">Send message</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ChatView',
  props: ["activeConversation", "name"],
  data() {
    return {
      messages: [],
      messageText: "",
      isSignedInUser: false,
      scrollY: 0
    }
  }, 
  methods: {
    sendMessage: function() {
      if(this.messageText == ""){
        return
      }
      const myMessageText = this.messageText
      this.messageText = ""
      const myMessage = {"text": myMessageText, "person": "me"}
      this.messages.push(myMessage)
      this.$nextTick(() => {
        const box = this.$refs.chatview
        box.scrollTop = box.scrollHeight;
      })

      const api_url = 'https://704wwqt0ah.execute-api.ap-northeast-1.amazonaws.com/default/miura_test'
      this.axios.post( api_url, {"body": myMessageText})
          .then(response => {
            var replayText = response.data
            if(this.messages.length == 2 && replayText == "ただいま"){
              replayText = "うん"
            }
            const replay = {"text": replayText, "person": "friend"}
            console.log(replay)
            setTimeout( () => {
              this.messages.push(replay)
              this.$nextTick(() => {
                const box = this.$refs.chatview
                box.scrollTop = box.scrollHeight;
              })
            }, 1000);
          }).catch(error => console.log(error))
    }, 
  },
  mounted: function() {
    const api_url = 'https://704wwqt0ah.execute-api.ap-northeast-1.amazonaws.com/default/miura_test'
    this.axios.post( api_url, {"body": "おかえり"})
          .then(response => {
            const replayText = response.data
            const replay = {"text": replayText, "person": "friend"}
            console.log(replay)
            this.messages.push(replay)
          }).catch(error => console.log(error))

    // const box = this.$refs.chatview
    // box.onscroll = (e) => {
    //   const top =  e.target.scrollTop
    //   this.scrollY = top
    // }
    // box.onscroll();
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.conversation-container {
 margin: 0 auto;
 max-width: 400px;
 height: 70vh;
 padding: 0 20px;
 /* margin-top: 8vh; */
 border: 3px solid #f1f1f1;
 overflow: scroll;
}

.bubble-container {
 text-align: left;
}

.bubble {
 border: 2px solid #f1f1f1;
 background-color: #fdfbfa;
 border-radius: 5px;
 padding: 10px;
 margin: 10px 0;
 width: 230px;
 float: right;
 font-size: 14px;
}

.myMessage .bubble {
 background-color: #abf1ea;
 border: 2px solid #87E0D7;
 float: left;
}

.name {
 padding-right: 8px;
 font-size: 9px;
}

.input_container{
  padding: 20vh 0;
}

::-webkit-scrollbar {
 width: 10px;
}

::-webkit-scrollbar-track {
 background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
 background: #888;
}

::-webkit-scrollbar-thumb:hover {
 background: #555;
}
</style>
